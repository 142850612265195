.data-display-page {
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 40px 0;
}

.header {
  width: 100%;
  background-color: #343a40;
  padding: 20px;
}

.header-container {
  text-align: center;
}

.shining-text {
  font-size: 40px;
  font-weight: bold;
  animation: shine 2s infinite;
  background: linear-gradient(to right, #f0f, #ff0, #0ff);
  -webkit-background-clip: text;
  color: transparent;
}

@keyframes shine {
  from {
    background-position: -200px;
  }
  to {
    background-position: 200px;
  }
}

.card {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
  border: none;
}

.btn-danger:hover {
  background-color: #c82333;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.date-picker {
  flex: 1;
  display: flex;
}

.date-picker-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  text-align: center;
  box-sizing: border-box;
}

.data-table {
  margin-top: 10px;
}

.table {
  width: 100%;
  margin-top: 10px;
}

.table-dark {
  background-color: #343a40;
  color: #fff;
}

.table td,
.table th {
  padding: 12px;
}

/* Modal Background */
.modal.fade .modal-dialog {
  background-color: rgba(0, 0, 0, 0.5);
}

body.modal-open {
  overflow: hidden;
}

.modal-content {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-title {
  font-size: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 14px;
  color: #333;
}

.form-control {
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding: 10px;
  font-size: 14px;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: none;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.modal-header {
  border-bottom: none;
  position: relative;
}

.close {
  color: #333;
  font-size: 1.5rem;
  font-weight: 700;
  opacity: 0.5;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  background: none;
  border: none;
}

.fa-edit {
  cursor: pointer;
}

.fa-trash {
  cursor: pointer;
}

.menu-button {
  display: none;
  position: relative;
}

.menu-dropdown {
  position: absolute;
  top: 40px; /* Adjust based on the position of the FaBars icon */
  right: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 1000;
}

/* Dropdown menu list */
.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Menu items */
.menu-item-add,
.menu-logout {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.menu-item-add {
  color: #007bff;
  border-bottom: 1px solid #e9ecef;
}

.menu-logout {
  color: #dc3545;
}

.menu-item-add:hover,
.menu-logout:hover {
  background-color: #f1f1f1;
  color: #000;
}

/* Optional: Icon styles */
.menu-item-add:before,
.menu-logout:before {
  content: "\2022"; /* Use a bullet or any icon you prefer */
  display: inline-block;
  margin-right: 8px;
}

@media (max-width: 767.98px) {
  .menu-button {
    display: block;
  }

  .header-buttons {
    display: none;
  }
}

@media (min-width: 768px) {
  .menu-button {
    display: none;
  }

  .header-buttons {
    display: flex;
  }
}
