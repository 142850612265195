.data-display-page {
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 40px 0;
}

.header {
  width: 100%;
  background-color: #343a40;
}

.header-container {
  padding: 20px;
}

.shining-text {
  font-size: 40px;
  font-weight: bold;
  animation: shine 2s infinite;
  background: linear-gradient(to right, #f0f, #ff0, #0ff);
  -webkit-background-clip: text;
  color: transparent;
}

@keyframes shine {
  from {
    background-position: -200px;
  }
  to {
    background-position: 200px;
  }
}

.card {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.date-picker {
  flex: 1;
}

.date-picker-input {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
}

.dropdown-menu-button {
  display: flex;
  position: relative;
  cursor: pointer;
}

.menu-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 1000;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.menu-item {
  color: #007bff;
  border-bottom: 1px solid #e9ecef;
}

.menu-item:hover {
  background-color: #f1f1f1;
  color: #000;
}

.menu-item:before {
  content: "\2022";
  display: inline-block;
  margin-right: 8px;
}

.info-box {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  margin: 20px auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.info-text {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.whatsapp-sticker {
  cursor: pointer;
  color: #25d366;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.whatsapp-sticker svg {
  transition: color 0.3s ease;
  width: 100px;
  height: 80px;
}

.whatsapp-sticker:hover svg {
  color: #128c7e;
}

.data-table {
  margin-top: 10px;
}

.table {
  width: 100%;
  margin-top: 10px;
}

.table-dark {
  background-color: #343a40;
  color: #fff;
}

.rate-text {
  font-weight: bold;
}

@media (max-width: 768px) {
  .card {
    padding: 15px;
    min-height: auto;
  }

  .date-picker-input {
    font-size: 14px;
  }

  .table {
    font-size: 14px;
  }

  .info-text {
    font-size: 16px;
  }

  .whatsapp-sticker svg {
    width: 40px;
    height: 40px;
  }
}
