/* index.css */

body {
  font-family: Arial, sans-serif;
}

h1,
h2,
h3 {
  font-family: "Arial", sans-serif;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.login-page {
  text-align: center;
  margin-top: 50px;
}

.admin-dashboard {
  text-align: center;
  margin-top: 50px;
}
