.background-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, #9e9e9e 0%, #e0e0e0 100%);
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
}

.login-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  color: #333333;
  border: 1px solid #ddd;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  color: #333333;
  font-weight: 700;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #666666;
}

.form-control {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fafafa;
  color: #333;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
  outline: none;
  border-color: #f57c00;
  box-shadow: 0 0 8px rgba(245, 124, 0, 0.5);
}

.login-btn {
  width: 100%;
  padding: 12px;
  background-color: #f57c00;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #e65100;
}

.error-message {
  color: #e53935;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .login-card {
    padding: 30px;
  }
}
